import React from 'react';
import propTypes from 'prop-types';

import { black } from '.';

export default function Icon({ name, className, color, width, opacity }) {
  switch (name) {
    case 'close':
      return (
        <svg
          x="0px"
          y="0px"
          width={width ? `${width}px` : '24px'}
          height={width ? `${width}px` : '24px'}
          viewBox="0 0 31.11 31.11"
          enableBackground="new 0 0 31.11 31.11"
          className={className}
        >
          <polygon
            points="31.11,1.41 29.7,0 15.56,14.14 1.41,0 0,1.41 14.14,15.56 0,29.7 1.41,31.11 15.56,16.97   29.7,31.11 31.11,29.7 16.97,15.56"
            fill={color}
            opacity={opacity}
          />
        </svg>
      );
    case 'github-logo':
      return (
        <svg
          viewBox="0 0 121 119"
          className={className}
          width={width}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill={color} fillRule="evenodd">
            <path d="M60.609.446C27.262.446.221 27.481.221 60.833c0 26.681 17.303 49.317 41.297 57.302 3.018.559 4.126-1.31 4.126-2.905 0-1.44-.056-6.197-.082-11.243-16.8 3.653-20.345-7.125-20.345-7.125-2.747-6.98-6.705-8.836-6.705-8.836-5.479-3.748.413-3.671.413-3.671 6.064.425 9.257 6.223 9.257 6.223 5.386 9.232 14.127 6.563 17.573 5.02.542-3.902 2.107-6.568 3.834-8.075-13.413-1.527-27.513-6.705-27.513-29.844 0-6.593 2.359-11.98 6.222-16.209-.627-1.522-2.694-7.663.585-15.981 0 0 5.071-1.623 16.611 6.19 4.817-1.338 9.983-2.009 15.115-2.032 5.132.023 10.302.694 15.128 2.032 11.526-7.813 16.59-6.19 16.59-6.19 3.287 8.318 1.219 14.459.592 15.981 3.872 4.229 6.215 9.616 6.215 16.209 0 23.194-14.127 28.301-27.574 29.796 2.166 1.874 4.096 5.549 4.096 11.183 0 8.079-.07 14.582-.07 16.572 0 1.607 1.087 3.49 4.148 2.897 23.981-7.994 41.262-30.622 41.262-57.294 0-33.352-27.037-60.387-60.387-60.387" />
            <path d="M23.093 87.149c-.133.301-.605.39-1.035.184-.438-.197-.684-.606-.542-.907.13-.309.603-.394 1.04-.189.439.198.689.611.537.912M25.539 89.877c-.288.267-.851.143-1.233-.279-.395-.421-.469-.984-.177-1.255.297-.267.843-.142 1.239.279.395.426.472.985.171 1.255M27.92 93.355c-.37.257-.975.016-1.349-.521-.37-.537-.37-1.181.008-1.439.375-.258.971-.026 1.35.507.369.545.369 1.19-.009 1.453M31.182 96.715c-.331.365-1.036.267-1.552-.231-.528-.487-.675-1.178-.343-1.543.335-.366 1.044-.263 1.564.231.524.486.684 1.182.331 1.543M40.624 99.028c.017.498-.563.911-1.281.919-.722.017-1.306-.386-1.314-.876 0-.503.567-.912 1.289-.924.718-.014 1.306.386 1.306.881M45.223 98.245c.086.486-.413.985-1.126 1.117-.701.129-1.35-.171-1.439-.653-.087-.498.421-.997 1.121-1.126.714-.124 1.353.168 1.444.662M35.682 98.666c-.146.473-.825.688-1.509.487-.683-.207-1.13-.761-.992-1.239.142-.476.824-.7 1.513-.485.682.206 1.13.756.988 1.237" />
          </g>
        </svg>
      );
    case 'twitter-logo':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 100 82"
          width={width}
          className={className}
        >
          <path
            fill="#1DA1F2"
            d="M31.45 81.27c37.738 0 58.374-31.263 58.374-58.374 0-.888-.018-1.772-.058-2.652A41.72 41.72 0 00100 9.62a40.91 40.91 0 01-11.782 3.23c4.236-2.54 7.488-6.558 9.022-11.348a41.145 41.145 0 01-13.028 4.98A20.492 20.492 0 0069.236 0c-11.33 0-20.519 9.188-20.519 20.514 0 1.61.18 3.176.532 4.678-17.052-.858-32.172-9.022-42.29-21.436a20.462 20.462 0 00-2.778 10.312 20.5 20.5 0 009.13 17.078A20.366 20.366 0 014.02 28.58c-.003.086-.003.17-.003.262 0 9.936 7.072 18.232 16.46 20.113a20.543 20.543 0 01-9.266.351c2.612 8.153 10.186 14.085 19.166 14.25A41.159 41.159 0 014.894 72.34 41.68 41.68 0 010 72.055a58.077 58.077 0 0031.45 9.216"
          />
        </svg>
      );
    case 'linkedin-logo':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 101 102"
          width={width}
          className={className}
        >
          <path
            fill="#069"
            d="M.548 7.822c0-3.98 3.309-7.208 7.39-7.208h85.22c4.082 0 7.39 3.228 7.39 7.208V94.04c0 3.982-3.308 7.209-7.39 7.209H7.939c-4.081 0-7.39-3.227-7.39-7.209V7.822z"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M30.861 84.855V39.413H15.758v45.442h15.104zM23.31 33.209c5.267 0 8.546-3.49 8.546-7.85-.098-4.46-3.279-7.852-8.446-7.852-5.166 0-8.544 3.392-8.544 7.851 0 4.361 3.277 7.85 8.346 7.85h.098zm15.91 51.646h15.103V59.478c0-1.358.098-2.715.497-3.686 1.092-2.713 3.577-5.523 7.75-5.523 5.465 0 7.652 4.167 7.652 10.276v24.31h15.102V58.799c0-13.958-7.451-20.452-17.388-20.452-8.149 0-11.726 4.554-13.713 7.656h.1v-6.59H39.22c.198 4.264 0 45.442 0 45.442z"
            clipRule="evenodd"
          />
        </svg>
      );
    default:
      return <div />;
  }
}

Icon.propTypes = {
  name: propTypes.string,
  color: propTypes.string,
  opacity: propTypes.number,
  width: propTypes.number,
  className: propTypes.string,
};

Icon.defaultProps = {
  name: '',
  color: black,
  opacity: 1,
  width: 35,
  className: '',
};
