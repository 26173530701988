import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <IndexPageStyles>
      <p>
        I&apos;m Daniel Bruce Wong (
        <strong>
          <a
            href="https://twitter.com/dbwdev"
            rel="noopener noreferrer"
            target="_blank"
          >
            @dbw
          </a>
        </strong>
        ), a senior software engineer at Change Healthcare.
      </p>

      <p>
        I love the work that I do every day as a software engineer. I&apos;m
        especially passionate about optimizing frontend builds and enabling
        scalable backends with serverless architecture.
      </p>
      <p>
        I&apos;m honored to hold degrees from two institutions that have deep
        connections to computer science history — Vassar College, the alma mater
        of COBOL pioneer Admiral Grace Hopper, and Stanford University, one of
        the world&apos;s best universities for all things bits and bytes.
      </p>
      <p>
        As a slow traveler, I love to pick a place, lay down my roots there, and
        spend several months taking in the scenery, vibes, and culture. Some of
        my favorite slow travel destinations have been Berlin, Paris, Beijing,
        Singapore, and Bali. Next on my bucket list is South America — perhaps
        Peru and Argentina?
      </p>
      <p>
        I occassionally give talks on software engineering topics, especially{' '}
        <strong>serverless architecture</strong> and modern JavaScript
        frameworks like <strong>React</strong> and <strong>Svelte</strong>. If
        you&apos;d like me to speak at your next event, please
        <a href="mailto:dbw@dbw.dev"> drop me a line here</a>.
      </p>
      <figure>
        <figcaption>
          Waves crashing down on the cliffs of Uluwatu, Bali just before the end
          of 2018
        </figcaption>
        <Img fluid={data.image.childImageSharp.fluid} />
      </figure>
    </IndexPageStyles>
  </Layout>
);

IndexPage.propTypes = {
  data: propTypes.shape({
    image: propTypes.object,
  }),
};

IndexPage.defaultProps = {
  data: ``,
};

export default IndexPage;

export const indexPageQuery = graphql`
  query {
    image: file(relativePath: { eq: "uluwatu.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const IndexPageStyles = styled.article`
  figure {
    margin: 0;
  }

  figcaption {
    font-size: 0.9rem;
    font-style: italic;
    margin-bottom: 0.5rem;
    opacity: 0.7;
    text-align: center;
  }

  p {
    margin-bottom: 2rem;
  }
`;
