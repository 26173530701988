import React from 'react';
import styled from 'styled-components';

import Icon from '../utils/Icon';

const Footer = () => (
  <FooterStyles>
    <a
      href="https://github.com/dbwdev"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon className="icon github" name="github-logo" />
    </a>
    <a
      href="https://twitter.com/dbwdev"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon className="icon twitter" name="twitter-logo" />
    </a>
    <a
      href="https://www.linkedin.com/in/dawong"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon className="icon linkedin" name="linkedin-logo" />
    </a>
    <div className="legal">
      <small>
        © 2019 Daniel Bruce Wong. Licensed under{' '}
        <a
          href="http://creativecommons.org/licenses/by/4.0/legalcode"
          rel="noopener noreferrer"
          target="_blank"
        >
          CC-BY-4.0.
        </a>
      </small>
    </div>
  </FooterStyles>
);

export default Footer;

const FooterStyles = styled.footer`
  margin: 2rem 1rem;
  text-align: center;

  .legal {
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    margin-top: 1rem;
  }

  .twitter {
    margin: 0 1rem;
  }
`;
